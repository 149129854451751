<template>
  <div class="container-cars">
    <ProTable
      :columns="columns"
      :request="request"
      ref="table"
    >
      <template v-slot:actions>
        <el-button
          type="danger"
          @click="clearDialogVisible = true"
          size="small"
        >清&nbsp;&nbsp;空</el-button>
      </template>
      <template v-slot:options="data">
        <el-button
          type="primary"
          icon="el-icon-picture"
          size="mini"
          @click="showImgDialogVisible(data.scope.row.inImage)"
        >查看图片</el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          @click="deleteInnerCar(data.scope.row.chargeId)"
        >
          删 除
        </el-button>
      </template>
    </ProTable>
    <el-dialog
      title="识别图片"
      :visible.sync="imgDialogVisible"
      width="80%"
      @close="imgDialogClose"
    >
      <div
        class="flex-row"
        style="align-items: center; justify-content: center"
      >
        <el-image
          :src="images.pic"
          style="max-width: 700px"
          :preview-src-list="[images.pic]"
        ></el-image>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="imgDialogClose"
        >关 闭</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="场内车清空"
      :visible.sync="clearDialogVisible"
      width="50%"
      @close="clearDialogClose"
    >
      <el-form
        :model="clearForm"
        ref="clearFormRef"
        label-width="150px"
      >
        <el-form-item
          label="清空时间起"
          prop="start"
        >
          <el-date-picker
            v-model="clearForm.start"
            type="datetime"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="清空时间止"
          prop="end"
        >
          <el-date-picker
            v-model="clearForm.end"
            type="datetime"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="clearDialogClose">取 消</el-button>
        <el-button
          type="primary"
          :loading="clearLoading"
          @click="clearInnerCar"
        >清 空</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { dateFormatYMDHMS } from "@/utils/utils";
import ProTable from "../procomponents/ProTable.vue";
export default {
  name: "cars",
  components: {
    ProTable,
  },
  data() {
    return {
      columns: [
        {
          label: "车牌号码",
          name: "carno",
          type: "input",
          dataFormater: (value) => {
            return {
              plateNo: value,
            };
          },
        },
        {
          hideInSearch: true,
          label: "订单编号",
          name: "chargeId",
          type: "input",
        },
        {
          hideInSearch: true,
          label: "出入口",
          name: "pointname",
          type: "input",
        },
        {
          label: "驶入时间",
          name: "inTime",
          type: "dateRange",
          dataFormater: (value) => {
            return {
              startDate: value[0],
              endDate: value[1],
            };
          },
        },
        {
          label: "区域",
          type: "select",
          name: "regionCode",
          dataSource: [],
        },
        {
          hideInSearch: true,
          label: "操作",
          fixed: "right",
          width: 250,
          name: "options",
          slot: "options",
        },
      ],

      clearDialogVisible: false,
      imgDialogVisible: false, // 控制图片窗口显示
      start: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        0,
        0,
        0
      ),
      end: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        23,
        59,
        59
      ),
      baseUrl: "",
      images: {
        pic: "",
      },
      clearForm: {
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          0,
          0,
          0
        ),
        end: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          23,
          59,
          59
        ),
      },
      clearLoading: false,
    };
  },
  created() {
    this.baseUrl = this.$store.state.baseUrl; // 请求base地址
    this.getRegionDropData();
  },
  methods: {
    async request(query) {
      var { data: res } = await this.$http.get(
        this.$store.state.baseUrl + "queryInnerCarList",
        {
          params: query,
        }
      );
      res = JSON.parse(res);
      return res;
    },
    // 查询出入记录
    async queryList() {
      this.$refs.table.queryData();
    },
    // 关闭清空窗口
    clearDialogClose() {
      this.clearForm.start = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        0,
        0,
        0
      );
      this.clearForm.end = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        23,
        59,
        59
      );
      this.clearDialogVisible = false;
    },

    // 打开订单图片窗口
    showImgDialogVisible(pic) {
      // 设置入场出场图片
      this.images.pic = pic;
      this.imgDialogVisible = true;
    },

    // 关闭订单图片窗口
    imgDialogClose() {
      this.images.pic = "";
      this.imgDialogVisible = false;
    },

    // 场内车删除
    deleteInnerCar(orderNo) {
      this.$confirm("确定删除该场内车？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(async () => {
          const userId = window.localStorage.getItem("LOGIN_USERID");
          try {
            var { data: res } = await this.$http.get(
              this.baseUrl + "deleteInnerCar",
              {
                params: {
                  orderNo: orderNo,
                  userId: userId,
                  parkCode: window.localStorage.getItem("LOGIN_PACKCODE"),
                },
              }
            );
            res = JSON.parse(res);
            if (res.code === "0") {
              this.$message.success(res.message);
              this.queryList();
            } else {
              return this.$message.error(res.message);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {});
    },

    // 场内车清空
    clearInnerCar() {
      if (!this.clearForm.start) {
        return this.$message.error("清空日期起不能为空");
      }
      if (!this.clearForm.end) {
        return this.$message.error("清空日期止不能为空");
      }

      this.$confirm("确定清空场内车？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(async () => {
          console.info("clean");
          this.clearLoading = true;
          const userid = window.localStorage.getItem("LOGIN_USERID");
          try {
            var { data: res } = await this.$http.get(
              this.baseUrl + "clearInnerCar",
              {
                params: {
                  userid: userid,
                  start: dateFormatYMDHMS(this.clearForm.start),
                  end: dateFormatYMDHMS(this.clearForm.end),
                  parkCode: window.localStorage.getItem("LOGIN_PACKCODE"),
                },
              }
            );
            res = JSON.parse(res);
            this.clearLoading = false;
            if (res.code === "0") {
              this.$message.success(res.message);
              this.queryList();
              this.clearDialogClose();
            } else {
              return this.$message.error(res.message);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {})
        .finally(() => (this.clearLoading = false));
    },
    // 区域列表下拉
    getRegionDropData() {
      const tmp = [];
      const regionList = tmp.concat(this.$store.state.parkConfig.regionList);
      const list = [];
      for (let i = 0; i < regionList.length; i++) {
        const temp = {
          label: regionList[i].regionName,
          value: regionList[i].regionCode,
        };
        list.push(temp);
      }
      this.columns[4].dataSource = list;
    },
  },
};
</script>

<style lang="less" scoped>
.demo-image__placeholder {
  .block {
    padding: 30px 0;
    text-align: center;
    border-right: 1px solid #eff2f6;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    width: 100%;
    &:last-child {
      border-right: none;
    }
  }
  .el-image {
    width: 450px;
    height: 300px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
}
</style>
